<template>
  <div class="mt-5">
    <HomeContent v-if="!channelData" @channel-fetched="setChannelData" />
    <ChannelInfo
      v-if="channelData"
      :channel-data="channelData"
      @back="resetChannelData"
    />
  </div>
</template>

<script setup>
import { useChannelData } from "@/composables/useChannelData";
import HomeContent from "@/components/home/HomeContent.vue";
import ChannelInfo from "@/components/home/ChannelInfo.vue";

const { channelData, setChannelData, resetChannelData } = useChannelData();
</script>

<style scoped></style>
