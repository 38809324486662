<template>
  <div class="mt-5">
    <div class="container channel-info pt-5">
      <div class="row">
        <div class="col mx-2 text-center">
          <div class="d-grid gap-2 col-12 w-50-lg mx-auto mt-5">
            <h1>{{ channelName }}</h1>
            <img
              :src="logoUrl"
              alt="Channel Thumbnail"
              class="thumbnail rounded-circle mx-auto"
            />

            <h2 class="mt-4">
              Gracias por confirmar tu servicio gratuito. Este proceso tomará
              aproximadamente 24 horas en completarse.
            </h2>
            <h2 class="mt-4">
              Nos pondremos en contacto contigo una vez que los suscriptores se
              hayan añadido.
            </h2>
            <img
              src="@/assets/images/verified.png"
              alt="Channel Thumbnail"
              class="thumbnail rounded-circle mx-auto mt-4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import axios from "axios";
import { useNotification } from "@kyvg/vue3-notification";
const notification = useNotification();

const queryParams = new URLSearchParams(window.location.search);
const channelUrl = queryParams.get("channelUrl");
const logoUrl = queryParams.get("logoUrl");
const channelName = queryParams.get("channelName");

onMounted(() => {
  submitForm();
});

const submitForm = async () => {
  const end_point = "/add-data-google-sheet";
  const apiUrl = `${process.env.VUE_APP_API_URL}${end_point}`;
  try {
    const response = await axios.post(
      apiUrl,
      {
        url_channel: channelUrl,
        verified: true,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Security-Token": process.env.VUE_APP_API_TOKEN,
        },
      },
    );

    if (response.data.status === "success") {
      notification.notify({
        text: "Correo verificado",
        type: "success",
      });
    }
  } catch (error) {
    console.error("Error al enviar la información:", error);
  }
};
</script>

<style scoped></style>
