// src/composables/useChannelData.js
import { ref } from "vue";

const channelData = ref(null);

export function useChannelData() {
  const setChannelData = (data) => {
    channelData.value = data;
  };

  const resetChannelData = () => {
    channelData.value = null;
  };

  return {
    channelData,
    setChannelData,
    resetChannelData,
  };
}
