<template>
  <div class="container mx-auto px-0 text-center">
    <div class="row mx-3">
      <div class="col">
        <h1 class="w-40-lg mt-4 mb-0 mx-auto">
          ¡Incrementa tus suscriptores de YouTube
          <span class="text-red">GRATIS</span>!
        </h1>
        <h4 class="w-50-lg mx-auto mb-3">
          Aumenta la visibilidad de tu canal.
        </h4>
        <div class="w-40-lg div-space color-1 my-2 mx-auto">
          "Destaca en búsquedas de YouTube."
        </div>
        <div class="w-40-lg div-space color-2 my-2 mx-auto">
          "Gana credibilidad y más público."
        </div>
        <img src="@/assets/images/main.png" class="mt-2 img-fluid" />
        <!-- Versión móvil -->
        <div class="mt-4 w-40-lg mx-auto">
          <div class="input-group input-group-lg input-wrapper">
            <input
              v-model="youtubeUrl"
              type="text"
              class="form-control input-custom"
              @focus="isFocused = true"
              @blur="isFocused = false"
              @keyup.enter="searchVideoOrChannel"
            />
            <div v-if="!youtubeUrl && !isFocused" class="custom-placeholder">
              <i class="bi bi-youtube"></i>
              <span>Ingresa el link de tu canal</span>
            </div>
          </div>
          <div class="text-center w-60-lg mx-auto">
            <button
              class="btn btn-primary custom-shadow btn-mobile btn-lg"
              type="button"
              @click="searchVideoOrChannel"
            >
              <div v-if="!loading">Obtener Suscriptores</div>
              <div v-else>
                <span
                  class="spinner-border spinner-border-sm"
                  aria-hidden="true"
                ></span>
                <span role="status" class="mx-3">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <h5 class="mt-3 w-30-lg mx-auto">
          "Empieza a crecer tu canal hoy, sin compromiso."
        </h5>
      </div>
    </div>
  </div>
  <!-- <footer class="mt-5 d-block d-lg-none">
    <div class="container">
      <div class="row text-center">
        <div
          class="col-lg-6 col-sm-12 d-flex justify-content-center justify-content-lg-end align-items-center mb-4 mb-lg-0"
        >
          <img
            src="@/assets/logos/google_ads.svg"
            class="logo google-logo"
            alt="Google Ads Logo"
          />
        </div>
        <div
          class="col-lg-6 col-sm-12 d-flex justify-content-center justify-content-lg-start align-items-center"
        >
          <img
            src="@/assets/logos/developed-with-youtube-lowercase-dark.png"
            class="logo youtube-logo"
            alt="YouTube Logo"
          />
        </div>
      </div>
    </div>
  </footer> -->
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";
import axios from "axios";
import { useNotification } from "@kyvg/vue3-notification";

const notification = useNotification();
const youtubeUrl = ref("");
const loading = ref(false);
const isFocused = ref(false);

const { emit } = getCurrentInstance(); // Obtener emit desde la instancia

// Función para extraer el ID del video o canal
function getYoutubeVideoOrChannelId(url) {
  const videoRegExp =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const channelRegExp =
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:channel\/([a-zA-Z0-9_-]+)|@([a-zA-Z0-9_-]+))/;

  const videoMatch = url.match(videoRegExp);
  const channelMatch = url.match(channelRegExp);

  if (videoMatch && videoMatch[1]) {
    console.log("video match", videoMatch[1]);
    return { type: "video", id: videoMatch[1] };
  } else if (channelMatch) {
    const channelId = channelMatch[1] || channelMatch[2];
    console.log("channel match", channelId);
    return { type: "channel", id: channelId };
  }

  return null;
}

// Función para buscar la información del video o canal y obtener el canal siempre
async function searchVideoOrChannel() {
  const result = getYoutubeVideoOrChannelId(youtubeUrl.value);
  if (!result) {
    notification.notify({
      title: "Enlace inválido",
      text: "Agregue un enlace de YouTube válido (video o canal)",
      type: "error",
    });
    return;
  }

  loading.value = true;
  const apiKey = process.env.VUE_APP_GOOGLE_API_KEY;

  try {
    let channelId;

    // Si es un video, obtenemos el channelId del video
    if (result.type === "video") {
      const videoResponse = await axios.get(
        `https://www.googleapis.com/youtube/v3/videos?id=${result.id}&key=${apiKey}&part=snippet`,
      );

      if (videoResponse.data.items && videoResponse.data.items.length > 0) {
        const videoInfo = videoResponse.data.items[0];
        channelId = videoInfo.snippet.channelId; // Extraer el channelId del video
      } else {
        throw new Error("No se encontró el video especificado.");
      }
    } else if (result.type === "channel") {
      // Si es un `@username`, realizar una búsqueda para obtener el channelId
      if (youtubeUrl.value.includes("@")) {
        const searchResponse = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?part=snippet&type=channel&q=${result.id}&key=${apiKey}`,
        );

        if (searchResponse.data.items && searchResponse.data.items.length > 0) {
          channelId = searchResponse.data.items[0].snippet.channelId;
        } else {
          throw new Error(
            "No se encontró el canal especificado por el username.",
          );
        }
      } else {
        // Si es un ID tradicional de canal, lo usamos directamente.
        channelId = result.id;
      }
    }

    // Validar que se obtuvo un channelId
    if (!channelId) {
      throw new Error("No se pudo determinar el ID del canal.");
    }

    // Realizar la solicitud para obtener los detalles del canal usando el channelId.
    const channelResponse = await axios.get(
      `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics,contentDetails,brandingSettings&id=${channelId}&key=${apiKey}`,
    );

    // Verificar si la respuesta contiene información del canal
    if (channelResponse.data.items && channelResponse.data.items.length > 0) {
      const channelInfo = channelResponse.data.items[0];

      // Obtener la miniatura disponible, priorizando la de mayor resolución.
      const thumbnails = channelInfo.snippet.thumbnails;
      const thumbnailUrl =
        thumbnails.default?.url ||
        thumbnails.medium?.url ||
        thumbnails.high?.url ||
        "";

      // Filtrar solo los datos que nos interesan.
      console.log(channelInfo);

      let urlChannel = "";

      if (channelInfo.snippet.customUrl) {
        urlChannel = `https://www.youtube.com/${channelInfo.snippet.customUrl}`;
        console.log("Se enviara solo el canal: ", urlChannel);
        submitChannel(urlChannel);
      }

      const channelData = {
        id: channelId,
        title: channelInfo.snippet.title,
        urlChannel: urlChannel,
        viewCount: parseInt(channelInfo.statistics.viewCount).toLocaleString(
          "es-CL",
        ),
        subscriberCount: parseInt(
          channelInfo.statistics.subscriberCount,
        ).toLocaleString("es-CL"),
        videoCount: parseInt(channelInfo.statistics.videoCount).toLocaleString(
          "es-CL",
        ),
        thumbnail: thumbnailUrl,
      };

      console.log("Channel Data", channelData);

      // Emitir un evento personalizado con la información del canal
      emit("channel-fetched", channelData);
    } else {
      throw new Error("No se encontró el canal especificado.");
    }

    loading.value = false;
  } catch (error) {
    console.error("Error al obtener los datos de YouTube:", error);
    notification.notify({
      title: "Error al obtener datos",
      text: "Hubo un error al intentar obtener los datos del canal. Por favor, verifica el enlace ingresado.",
      type: "error",
    });
    loading.value = false;
  }
}

const submitChannel = async (urlChannel) => {
  const end_point = "/add-data-google-sheet";
  const apiUrl = `${process.env.VUE_APP_API_URL}${end_point}`;
  try {
    const response = await axios.post(
      apiUrl,
      {
        url_channel: urlChannel,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Security-Token": process.env.VUE_APP_API_TOKEN,
        },
      },
    );

    if (response.data.status === "success") {
      console.log("Canal subido correctamente");
    } else {
      console.error("El canal no se ha subido");
    }
  } catch (error) {
    console.error("Error al enviar el canal:", error);
  }
};
</script>

<style scoped>
/* Máximo ancho para dispositivos móviles (xs, sm, md) */
.max-w-100 {
  max-width: 100%;
}

.btn-mobile {
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 800;
}

/* Footer */
/* Tamaño original de los logos */
.google-logo {
  width: 140px;
}

.youtube-logo {
  width: 180px;
}

.img-sized {
  max-width: 76%;
}

.text-red {
  color: #f61c0d;
}

/* Máximo ancho para pantallas grandes (lg) */
@media (min-width: 992px) {
  .row {
    margin-left: 2em;
    margin-right: 2em;
  }

  .col-lg-6 {
    padding-left: 2em;
    padding-right: 2em;
  }

  .img-sized {
    max-width: 90%;
  }
}

.div-space {
  font-family: "Nunito", sans-serif;
  font-size: clamp(0.8rem, calc(15 / 375 * 100vw), 2.5rem);
  padding: 0.5em 0 !important;
  color: #fff;
}

.color-1 {
  background-color: #17a5fd;
}

.color-2 {
  background-color: #a4a4a4;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-placeholder {
  position: absolute;
  display: flex;
  align-items: center;
  color: #000; /* Color del texto del placeholder */
  pointer-events: none;
  font-size: clamp(1rem, calc(13 / 375 * 100vw), 1.1rem) !important;
}

.custom-placeholder i {
  color: #ff0000; /* Rojo de YouTube */
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

.form-control {
  text-align: center; /* Asegura que el texto del input esté centrado */
  padding-left: 0; /* Ajuste del padding si es necesario */
}
</style>
