<template>
  <header>
    <nav class="navbar navbar-dark bg-dark fixed-top">
      <div class="container">
        <!-- Logo centrado en todas las pantallas -->
        <router-link
          class="navbar-brand translate-middle-x"
          to="/"
          @click="handleClick"
        >
          <img
            src="@/assets/logos/colfone-white.svg"
            alt="Logo"
            class="d-inline-block align-top logo-header"
            style="height: 1.75rem"
          />
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { useChannelData } from "@/composables/useChannelData";

const { resetChannelData } = useChannelData();

const handleClick = () => {
  resetChannelData();
};
//import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
//import { useUserStore } from "@/stores/userStore";
//import { computed } from "vue";
//import { useRouter } from "vue-router";
//const router = useRouter();

//const userStore = useUserStore();

// const userExist = computed(() => {
//   //const user = userStore.getUser;
//   if (userStore.getUser) return true;
//   return false;
// });

// const logout = () => {
//   userStore.clearUser();
//   cerrarOffcanvas();
//   router.push({ name: "Home" });
// };
// const cerrarOffcanvas = () => {
//   const offcanvasElemento = document.querySelector(".offcanvas");
//   const offcanvasInstancia = bootstrap.Offcanvas.getInstance(offcanvasElemento);
//   if (offcanvasInstancia) {
//     offcanvasInstancia.hide();
//   }
// };

// const handleImageError = (event) => {
//   event.target.src = require("@/assets/images/avatar.jpeg");
// };
</script>

<style scoped>
/* Eliminar el borde del botón de menú en estado hover o active */
.navbar-toggler.no-outline {
  box-shadow: none;
  border: none !important;
}

/* Solo cambiar el color del ícono del menú (navbar-toggler-icon) */
.navbar-toggler-icon {
  width: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.bg-dark {
  background-color: #000 !important;
}

/* Estilo para que el offcanvas ocupe solo el 60% en móviles */
.custom-offcanvas {
  width: 60% !important;
  background-color: #000 !important; /* Fondo negro */
  color: #fff !important; /* Texto blanco */
}

/* Asegurar que el texto y los elementos sean blancos */
.custom-offcanvas .nav-link,
.custom-offcanvas .dropdown-item {
  color: #fff !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.custom-offcanvas .btn-close-white {
  filter: invert(100%); /* Hace que el ícono del botón de cierre sea blanco */
}

/* Asegurar que el logo esté siempre centrado */
.navbar-brand {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

/* Para evitar que el botón de menú y el avatar afecten la posición del logo */
.container-fluid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.navbar-toggler {
  border: none;
}

.dropdown-menu {
  right: 0;
  left: auto;
}
</style>
