<template>
  <div class="container channel-info pt-5">
    <div class="row">
      <div class="col mx-2">
        <h1>{{ channelData.title }}</h1>
        <img
          :src="channelData.thumbnail"
          alt="Channel Thumbnail"
          class="thumbnail rounded-circle"
        />

        <div v-if="!questionSolved" id="question">
          <h2 class="my-3 w-50-lg mx-auto">
            ¡Genial! Tu canal ya tiene
            <span class="color-1">{{ channelData.videoCount }}</span> videos y
            <span class="color-1">{{ channelData.subscriberCount }}</span>
            suscriptores. ¡Vamos por más!
          </h2>
          <div class="div-space background-color-1 w-50-lg mx-auto">
            <h2 class="text-white px-3">
              ¡Consigue {{ subscribers }} suscriptores gratis ahora y acelera el
              crecimiento de tu canal!
            </h2>
          </div>

          <div class="d-grid gap-2 col-12 mx-auto mt-4 w-40-lg">
            <button
              class="btn btn-primary rounded-pill custom-shadow"
              type="button"
              @click="answerQuestion(true)"
            >
              Sí, quiero
            </button>
            <button
              class="btn btn-dark rounded-pill custom-shadow mt-2"
              type="button"
              @click="answerQuestion(false)"
            >
              No estoy interesado
            </button>
            <button
              class="btn btn-dark rounded-pill custom-shadow mt-2"
              type="button"
              @click="goBack()"
            >
              Atras
            </button>
          </div>
        </div>
        <div v-else>
          <div v-if="answerYes" id="answer_yes">
            <div v-if="!emailSended2" class="mt-4 w-50-lg mx-auto">
              <h2 class="mb-3">Ingresar información</h2>
              <div class="input-group input-group-lg input-wrapper my-2">
                <input
                  v-model="form.mail"
                  type="text"
                  class="form-control input-custom"
                  @focus="isFocusedMail = true"
                  @blur="isFocusedMail = false"
                  @keyup.enter="searchVideoOrChannel"
                />
                <div
                  v-if="!form.mail && !isFocusedMail"
                  class="custom-placeholder"
                >
                  <i class="bi bi-envelope"></i>
                  <span>Email</span>
                </div>
              </div>
              <div class="input-group input-group-lg input-wrapper my-2">
                <input
                  v-model="form.whatsapp"
                  type="text"
                  class="form-control input-custom"
                  @focus="isFocusedWhatsapp = true"
                  @blur="isFocusedWhatsapp = false"
                  @keyup.enter="searchVideoOrChannel"
                />
                <div
                  v-if="!form.whatsapp && !isFocusedWhatsapp"
                  class="custom-placeholder"
                >
                  <i class="bi bi-whatsapp"></i>
                  <span>Whatsapp</span>
                </div>
              </div>

              <div class="div-space background-color-1 mx-auto mt-3">
                <h4 class="text-white px-3">
                  Revisa tu correo, haz clic en confirmar y empezaremos a añadir
                  los suscriptores a tu canal.
                </h4>
              </div>

              <div class="text-center w-60-lg mx-auto mt-3">
                <button
                  class="btn btn-primary btn-mobile custom-shadow"
                  type="button"
                  @click="sendEmail"
                >
                  <div v-if="!loading">Procesar servicio gratis</div>
                  <div v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      aria-hidden="true"
                    ></span>
                    <span role="status" class="mx-3">Loading...</span>
                  </div>
                </button>
                <button
                  class="btn btn-dark btn-mobile custom-shadow mt-3"
                  type="button"
                  @click="goBack()"
                >
                  Atras
                </button>
              </div>
            </div>
            <div v-else>
              <h2>
                Te hemos enviado un correo. Haz clic en el enlace para confirmar
                tu servicio.
              </h2>
              <img
                src="@/assets/images/send-email.png"
                alt="send email"
                class="img-fluid"
              />
            </div>
          </div>
          <div v-else id="answer_no">
            <div
              v-if="!emailSended2"
              class="d-grid gap-2 col-12 w-50-lg mx-auto mt-5"
            >
              <h2>
                Gracias. Si deseas más información sobre este y otros servicios,
                compartenos tu email.
              </h2>
              <div class="mt-4">
                <div class="input-group input-group-lg input-wrapper my-2">
                  <input
                    v-model="form.mail"
                    type="text"
                    class="form-control input-custom"
                    @focus="isFocusedMail = true"
                    @blur="isFocusedMail = false"
                    @keyup.enter="searchVideoOrChannel"
                  />
                  <div
                    v-if="!form.mail && !isFocusedMail"
                    class="custom-placeholder"
                  >
                    <i class="bi bi-envelope"></i>
                    <span>Email</span>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary rounded-pill custom-shadow mt-2"
                type="button"
                @click="sendEmail"
              >
                Enviar correo
              </button>
              <button
                class="btn btn-dark rounded-pill custom-shadow mt-2"
                type="button"
                @click="goBack()"
              >
                Atras
              </button>
            </div>
            <div v-else class="w-40-lg mx-auto">
              <h2>
                Te hemos enviado un correo. Haz clic en el enlace para confirmar
                tu servicio.
              </h2>
              <img
                src="@/assets/images/send-email.png"
                alt="send email"
                class="img-fluid mt-4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="back-button" @click="goBack">Volver</button> -->
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, defineProps, computed } from "vue";
import { useNotification } from "@kyvg/vue3-notification";

const notification = useNotification();

const emit = defineEmits(["back"]);

const props = defineProps({
  channelData: {
    type: Object,
    required: true,
  },
});

const questionSolved = ref(false);
const answerYes = ref(false);
const emailSended1 = ref(false);
const emailSended2 = ref(false);
const isFocusedMail = ref(false);
const isFocusedWhatsapp = ref(false);

const form = ref({
  mail: "",
  whatsapp: "",
});

// const emit = defineEmits(["back"]);

const goBack = () => {
  emit("back");
};

const answerQuestion = (answer) => {
  questionSolved.value = true;
  if (answer == true) {
    answerYes.value = true;
  } else {
    answerYes.value = false;
  }
};

const sendEmail = () => {
  if (!isFormValid.value) {
    notification.notify({
      text: errorMessage.value,
      type: "error",
    });
  } else {
    console.log(form.value);
    emailSended1.value = true;
    emailSended2.value = true;
    submitForm();
  }
};

// Computed para el mensaje de error
const errorMessage = computed(() => {
  const mailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expresión regular para validar correos
  const whatsappRegex = /^\+\d{11,15}$/; // Acepta números de WhatsApp en formato internacional

  if (!form.value.mail) {
    return "El correo electrónico es obligatorio.";
  }
  if (!mailRegex.test(form.value.mail)) {
    return "El formato del correo electrónico no es válido.";
  }
  if (!form.value.whatsapp) {
    return "El número de whatsapp es obligatorio.";
  }
  if (form.value.whatsapp && !whatsappRegex.test(form.value.whatsapp)) {
    return "El número de WhatsApp debe tener el formato +123456789012 y contener entre 11 y 15 dígitos.";
  }

  return ""; // No hay errores
});

// Computed para verificar si el formulario es válido
const isFormValid = computed(() => {
  return errorMessage.value === "";
});

const subscribers = computed(() => {
  return Number(props.channelData.subscriberCount.replace(/\./g, "")) > 999
    ? 100
    : 50;
});

const submitForm = async () => {
  const end_point = "/add-data-google-sheet";
  const apiUrl = `${process.env.VUE_APP_API_URL}${end_point}`;
  try {
    const response = await axios.post(
      apiUrl,
      {
        mail: form.value.mail,
        whatsapp: form.value.whatsapp,
        url_channel: props.channelData.urlChannel,
        subscribers: subscribers.value,
        title: props.channelData.title,
        thumbnail: props.channelData.thumbnail,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Security-Token": process.env.VUE_APP_API_TOKEN,
        },
      },
    );

    if (response.data.status === "success") {
      notification.notify({
        title: "Solicitud enviada",
        text: "La información se ha enviado exitosamente",
        type: "success",
      });
      form.value.mail = "";
      form.value.whatsapp = "";
    } else {
      notification.notify({
        title: "Solicitud no enviada",
        text: "Hubo un error al enviar la información. Por favor, intente de nuevo",
        type: "error",
      });
    }
  } catch (error) {
    console.error("Error al enviar la información:", error); //Esta es la lina 306
    notification.notify({
      title: "Solicitud no evianda",
      text: "Hubo un error al enviar la información. Por favor, intente de nuevo",
      type: "error",
    });
  }
};
</script>

<style scoped>
.channel-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.thumbnail {
  width: 150px;
  height: 150px;
  border-radius: 8px;
  object-fit: cover;
  margin-bottom: 16px;
}

.channel-details {
  max-width: 600px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.statistics {
  margin-top: 16px;
}

.back-button {
  margin-bottom: 16px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #0056b3;
}

.div-space {
  font-family: "Nunito", sans-serif;
  font-size: clamp(0.8rem, calc(15 / 375 * 100vw), 2.5rem);
  padding: 0.5em 0 !important;
  color: #fff;
}

.background-color-1 {
  background-color: #17a5fd;
}

.color-1 {
  color: #17a5fd;
}

.btn-mobile {
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 800;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-placeholder {
  position: absolute;
  display: flex;
  align-items: center;
  color: #000; /* Color del texto del placeholder */
  pointer-events: none;
  font-size: clamp(1rem, calc(13 / 375 * 100vw), 1rem) !important;
}

.custom-placeholder i {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

.form-control {
  text-align: center; /* Asegura que el texto del input esté centrado */
  padding-left: 0; /* Ajuste del padding si es necesario */
}
</style>
